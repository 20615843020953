<script setup lang="ts">
import { onMounted, ref } from "vue"

const props = defineProps({
  "modelValue": null
})

const emit = defineEmits(["update:modelValue"])

const input = ref(null)

onMounted(() => {
    if (input.value.hasAttribute("autofocus")) {
        input.value.focus()
    }
})

defineExpose({ focus: () => input.value.focus() })
</script>

<template>
    <input
        ref="input"
        class="rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 disabled:bg-gray-100 disabled:text-gray-600"
        :value="modelValue"
        v-bind="$attrs"
        @input="$emit('update:modelValue', $event.target.value)" />
</template>
